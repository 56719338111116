<template>
  <r-e-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      show-footer
      @click-submit="handleSubmitPublish"
      @click-cancel="handleCancel"
      @close="handleCancel"
  >
    <el-form
        title=""
        ref="formPublish"
        label-width="110px"
        size="small"
        :model="formPublish"
        :rules="rules"
    >
      <div class="flex">
        <el-form-item label="地址" prop="address">
          <el-input v-model="formPublish.address"></el-input>
        </el-form-item>
        <el-form-item label="房间号" prop="roomNum">
          <el-input v-model="formPublish.roomNum"></el-input>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="显示金额" prop="displayAmount">
          <el-input type="number" v-model="formPublish.displayAmount"></el-input>
        </el-form-item>
        <el-form-item label="面积" prop="area">
          <el-input v-model="formPublish.area"></el-input>
        </el-form-item>
      </div>
<!--      <div class="flex">
        <el-form-item label="房型" prop="houseType">
          <el-input v-model="formPublish.houseType"></el-input>
        </el-form-item>
        <el-form-item label="签约时间" prop="decorationTime">
          <el-date-picker
              v-model="formPublish.decorationTime"
              type="datetime"
              placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
      </div>-->
      <div class="flex">
        <el-form-item label="房间状态" prop="houseStatus">
          <el-input type="number" v-model="formPublish.houseStatus"></el-input>
        </el-form-item>
        <el-form-item label="房间创建人" prop="entryPerson">
          <el-input v-model="formPublish.entryPerson"></el-input>
        </el-form-item>
      </div>
<!--      <div class="flex">
        <el-form-item label="承租人名称" prop="leasorName">
          <el-input v-model="formPublish.leasorName"></el-input>
        </el-form-item>
        <el-form-item label="实际入住周期" prop="leaseCycle">
          <el-input v-model="formPublish.leaseCycle"></el-input>
        </el-form-item>
      </div>-->
      <div class="flex">
        <el-form-item label="房源备注" prop="comment">
          <el-input type="textarea" :rows="3" maxlength="500" show-word-limit
                    v-model="formPublish.comment"
                    placeholder="请输入房源备注（字数要求0~500）"></el-input>
        </el-form-item>
      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {formPublishRules} from "@/views/rental-housing-management/publish-create/data";

export default {
  name: "layer-edit-publish",
  data() {
    return {
      dialogTitle: "房源详情",
      dialogVisible: false,
      formPublish: {  },
      rules: formPublishRules()
    };
  },
  components: {  },
  methods: {
    layerEditPublishInit(row) {
      // 页面初始化，父页面传递数据
      this.dialogVisible = true;
      this.editPublishInit(row);
    },
    editPublishInit(row) {
      // 编辑页面回显数据
      /*getApartmentByUUID(row.uuid).then(res=>{
        this.formPublish = res.info;
        this.formPublish.displayAmount=(this.formPublish.displayAmount*100).toFixed(2);
        console.log(row,this.formPublish);
      });*/
    },
    handleSubmitPublish() {
      // 表单提交
      this.handleCancel("update");
    },
    handleCancel(closeType = "cancel") {
      // 取消、关闭弹窗
      this.dialogVisible = false;
      this.formPublish = {  };
      this.$refs["formPublish"].resetFields();
      // 新增、编辑成功，需要刷新数据
      if (closeType === "update") this.$parent.$refs.publishTableRef.getTableData();
    }
  }
};
</script>

<style lang="scss" scoped>
.el-select{
  width: 100%;
}
.flex{
  display: flex;
  /deep/ .el-form-item{
    flex: 1;
    white-space: nowrap;
  }
}
</style>
<style>
.v-modal{
  z-index: 2000!important;
}
</style>
